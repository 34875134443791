import { ICompanyState } from '@/shared/store/company/company.interfaces'
import Company from '@/shared/modules/company/company.model'

export enum CompanyGetters {
  getCompany = 'COMPANY/GET_COMPANY',
  getLoading = 'COMPANY/GET_LOADING'
}

export default {
  [CompanyGetters.getCompany]: (state: ICompanyState): Company|null => state.company,
  [CompanyGetters.getLoading]: (state: ICompanyState): boolean => state.loading
}
