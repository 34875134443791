import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/shared/store/auth/auth.index'
import company from '@/shared/store/company/company.index'
import global from '@/shared/store/global/global.index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    company,
    global,
  }
})
