import IRoutesMeta from '@/shared/interfaces/helpers/routes-meta.interface'
import companyRoutesMeta from '@/shared/helpers/routes-meta/company-routes-meta'
import adminRoutesMeta from '@/shared/helpers/routes-meta/admin-routes-meta'
import authRoutesMeta from '@/shared/helpers/routes-meta/auth-routes-meta'

export const routesMeta: IRoutesMeta = {
  ...companyRoutesMeta,
  ...adminRoutesMeta,
  ...authRoutesMeta,
}

const getRouteMeta = (route: string): IRoutesMeta | any => routesMeta[route] ? routesMeta[route] : {}
export default getRouteMeta
