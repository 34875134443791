









































import { Component, Vue, Watch } from 'vue-property-decorator'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import IGallery from '@/shared/interfaces/helpers/gallery.interface'
import Attachment from '@/shared/modules/attachment/attachment.model'
import { GlobalActions } from '@/shared/store/global/global.actions'

@Component({
  components: {
    VueSlickCarousel
  }
})
export default class Gallery extends Vue {
  @Watch('gallery.show') private handleShow() {
    setTimeout(() => {
      const { carouselOne, carouselTwo }: any = this.$refs
      this.carouselOne = carouselOne
      this.carouselTwo = carouselTwo
      this.bigCarouselSettings = {
        initialSlide: this.gallery.initialSlideIndex
      }
      this.smallCarouselSettings.initialSlide = this.gallery.initialSlideIndex
    })
  }

  carouselOne: any = null
  carouselTwo: any = null
  bigCarouselSettings: any = {}
  smallCarouselSettings: any = {
    arrows: false,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }

  closeGallery(): void {
    this.$store.dispatch(GlobalActions.closeGallery)
  }

  get gallery(): IGallery {
    return this.$store.getters[GlobalGetters.getGallery]
  }

  get attachments(): Attachment[] {
    return this.gallery.attachments
  }
}
