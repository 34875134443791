import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import Model from '@/shared/classes/model'
import IPositionResponse from '@/shared/modules/position/position-response.interface'

export default class Position extends Model<IModelResponse> {
  name!: string

  transform(data: IPositionResponse): void {
    super.transform(data)

    this.name = data.name
  }
}
