import ILocale from '@/shared/interfaces/helpers/locale.interface'

export const LOCALES: ILocale = {
  lt: {
    title: 'Lietuvių',
    flag: require('@/shared/assets/images/flags/lt.png'),
    code: 'LT',
    inVersions: ['lt', 'no'],
    additionalInVersions: ['lt', 'no']
  },
  no: {
    title: 'Norsk',
    flag: require('@/shared/assets/images/flags/no.png'),
    code: 'NO',
    inVersions: ['no'],
    additionalInVersions: ['lt', 'no']
  },
  en: {
    title: 'English',
    flag: require('@/shared/assets/images/flags/en.png'),
    code: 'EN',
    inVersions: ['lt', 'no'],
    additionalInVersions: ['lt', 'no']
  },
  pl: {
    title: 'Polish',
    flag: require('@/shared/assets/images/flags/pl.png'),
    code: 'PL',
    inVersions: ['no'],
    additionalInVersions: ['lt', 'no'],
  },
  ru: {
    title: 'Russian',
    flag: require('@/shared/assets/images/flags/ru.png'),
    code: 'RU',
    inVersions: ['no'],
    additionalInVersions: ['lt', 'no'],
  }
}

export const DEFAULT_LOCALE = 'lt';
export const LOCALE_ERROR = 'global-error';
