import { IOtherAccessToken } from '@/shared/interfaces/helpers/token.interface'

const authKey = 'authToken'
const otherAccessTokensKey = 'otherAccessTokens'

const getToken = (): null | string => localStorage.getItem(authKey)
const setToken = (token: string) => localStorage.setItem(authKey, token)
const clearToken = (): void => localStorage.removeItem(authKey)

const getOtherAccessTokens = (): IOtherAccessToken[] => {
  const otherAccess = localStorage.getItem(otherAccessTokensKey)
  if (! otherAccess) return []
  return JSON.parse(otherAccess)
}
const addOtherAccessToken = (otherAccessToken: IOtherAccessToken): void => {
  const otherAccess = localStorage.getItem(otherAccessTokensKey)
  if (! otherAccess) {
    localStorage.setItem(otherAccessTokensKey, JSON.stringify([otherAccessToken]))
    return
  }
  localStorage.setItem(otherAccessTokensKey, JSON.stringify([...JSON.parse(otherAccess), otherAccessToken]))
}
const removeOtherAccessToken = (userUuid: string): void => {
  let otherAccessTokens = getOtherAccessTokens()
  otherAccessTokens = otherAccessTokens.filter((otherAccessToken: IOtherAccessToken) => otherAccessToken.userUuid !== userUuid)
  localStorage.setItem(otherAccessTokensKey, JSON.stringify(otherAccessTokens))
}
const resetOtherAccessTokens = (): void => localStorage.removeItem(otherAccessTokensKey)

const tokenHelper = {
  getToken,
  setToken,
  clearToken,
  getOtherAccessTokens,
  addOtherAccessToken,
  removeOtherAccessToken,
  resetOtherAccessTokens,
}

export default tokenHelper
