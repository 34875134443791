import Service from '@/shared/classes/service'
import User from '@/shared/modules/user/user.model'
import IUserResponse from '@/shared/modules/user/user-response.interface'
import IResponse from '@/shared/interfaces/modules/response.interface'
import tokenHelper from '@/shared/helpers/token.helper'
import router, { Routes } from '@/shared/router'
import store from '@/shared/store'
import { AuthGetters } from '@/shared/store/auth/auth.getters'

export default class UserService extends Service {
  static prefix = 'users'

  static show(uuid: string): Promise<User> {
    return this.get<IUserResponse>(`${ this.prefix }/${ uuid }`)
      .then((response: IResponse<IUserResponse>) => new User(response.data.data))
  }

  static loginLike(user: User): Promise<any> {
    return this.post(`${ this.prefix }/login-like/${ user.uuid }`, {})
      .then((response: IResponse<any>) => {
        const token = tokenHelper.getToken()
        if (! token) return
        tokenHelper.addOtherAccessToken({
          userUuid: store.getters[AuthGetters.getUser].uuid,
          authToken: token,
        })
        tokenHelper.setToken(response.data.access_token)
        router.push({ name: Routes.redirection }).catch(() => {})
      })
  }

  static getStrict(uuid: string): Promise<User> {
    return this.get(`${ this.prefix }/${ uuid }/strict`, {})
      .then((response: IResponse<IUserResponse>) => new User(response.data))
  }

  static deactivate(uuid: string): Promise<any> {
    return this.post(`${ this.prefix }/${ uuid }/deactivate`, {})
  }

  static destroy(uuid: string): Promise<any> {
    return this.delete(`${ this.prefix }/${ uuid }`)
  }
}
