import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import beforeEach from '@/shared/helpers/before-each.helper'
import company from '@/shared/router/company'
import admin from '@/shared/router/admin'
import getRouteMeta from '@/shared/helpers/routes-meta/get-route-meta.helper'
import { AuthRoutes } from '@/shared/router/auth'

Vue.use(VueRouter)

export enum Routes {
  home = 'home',
  redirection = 'redirection',
}

const routes: RouteConfig[] = [
  admin(),
  {
    path: '/redirection',
    name: Routes.redirection,
    component: () => import('@/shared/views/Redirection.vue'),
    meta: {
      shouldLoggedIn: true,
    },
  },
  {
    path: '/',
    name: Routes.home,
    component: () => import('@/Home.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/TermsAndConditions.vue')
  },
  {
    path: '/login',
    name: AuthRoutes.login,
    component: () => import('@/auth/views/Login.vue'),
    meta: getRouteMeta(AuthRoutes.login),
  },
  {
    path: '/remind-password',
    name: AuthRoutes.remindPassword,
    component: () => import('@/auth/views/RemindPassword.vue')
  },
  {
    path: '/set-password/:token/:email',
    name: AuthRoutes.setPassword,
    component: () => import('@/auth/views/SetPassword.vue')
  },
  {
    path: '/privacy-policy',
    name: 'misc/privacy-policy',
    component: () => import('@/auth/views/PrivacyPolicy.vue'),
    meta: {
      shouldNotLoggedIn: true,
    },
  },
  {
    path: '/reset-password/:token/:email',
    name: AuthRoutes.resetPassword,
    component: () => import('@/auth/views/ResetPassword.vue')
  },
  {
    path: '/email-verification/:id/:hash',
    name: AuthRoutes.emailVerification,
    component: () => import('@/auth/views/EmailVerification.vue'),
  },
  company(),
  {
    path: '*',
    component: () => import('@/shared/components/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(beforeEach)

export default router
