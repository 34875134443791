import getBrowserLocale from '@/shared/helpers/browser-locale.helper'

const localeKey = 'locale'

const getLocale = (): string => localStorage.getItem(localeKey) || getBrowserLocale()
const setLocale = (locale: string) => localStorage.setItem(localeKey, locale)

export default {
  getLocale,
  setLocale,
}
