import companyState from '@/shared/store/company/company.state'
import companyGetters from '@/shared/store/company/company.getters'
import companyMutations from '@/shared/store/company/company.mutations'
import companyActions from '@/shared/store/company/company.actions'

export default {
  state: companyState,
  getters: companyGetters,
  mutations: companyMutations,
  actions: companyActions
}
