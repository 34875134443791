import http from '@/shared/http'
import IResponse from '@/shared/interfaces/modules/response.interface'
import { AxiosRequestConfig } from 'axios'

export default class Service {
  static get<T>(url: string, config?: AxiosRequestConfig): Promise<IResponse<T>> {
    return http.get(url, config)
  }

  static post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<IResponse<T>> {
    return http.post(url, data, config)
  }

  static put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<IResponse<T>> {
    return http.put(url, data, config)
  }

  static delete<T>(url: string, config?: AxiosRequestConfig): Promise<IResponse<T>> {
    return http.delete(url, config)
  }

  static patch<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<IResponse<T>> {
    return http.patch(url, data, config)
  }
}
