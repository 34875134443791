import Model from '@/shared/classes/model'
import IPendingToolMoveResponse from '@/shared/modules/pending-tool-move/pending-tool-move.interface'

export default class PendingToolMove extends Model<IPendingToolMoveResponse> {
  pendingMoveUuid!: string
  internalNo!: string
  name!: string
  oldLocation!: string
  newLocation!: string

  transform(data: IPendingToolMoveResponse) {
    super.transform(data)

    this.pendingMoveUuid = data.pending_move_uuid
    this.internalNo = data.internal_no
    this.name = data.name
    this.oldLocation = data.old_location
    this.newLocation = data.new_location
  }
}