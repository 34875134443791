import Service from '@/shared/classes/service'
import Company from '@/shared/modules/company/company.model'
import ICompanyResponse from '@/shared/modules/company/company-response.interface'
import IResponse from '@/shared/interfaces/modules/response.interface'
import Attachment from '@/shared/modules/attachment/attachment.model'
import { downloadFile } from '@/shared/helpers/download-file.helper'

export default class CompanyService extends Service {
  static prefix = 'companies'

  static loadStrict(slug: string): Promise<Company> {
    return this.get<ICompanyResponse>(`/strict-company/${ slug }`)
      .then((response: IResponse<ICompanyResponse>) => new Company(response.data))
  }

  static loadCurrentCompany(slug: string): Promise<Company> {
    return this.get<ICompanyResponse>(`/company/${ slug }`)
      .then((response: IResponse<ICompanyResponse>) => new Company(response.data))
  }

  static destroy(uuid: string): Promise<any> {
    return this.delete(`/${ this.prefix }/${ uuid }`)
  }

  static deactivate(uuid: string): Promise<any> {
    return this.put(`/${ this.prefix }/${ uuid }/deactivate`, {})
  }

  static activate(uuid: string): Promise<any> {
    return this.put(`/${ this.prefix }/${ uuid }/activate`, {})
  }

  static downloadAttachment(company: Company, attachment: Attachment): Promise<any> {
    return this.get(`/${ this.prefix }/${ company.uuid }/attachments/${ attachment.uuid }/download`, { responseType: 'blob' })
  }

  static deleteAttachment(company: Company, attachment: Attachment): Promise<any> {
    return this.delete(`/${ this.prefix }/${ company.uuid }/attachments/${ attachment.uuid }`)
  }
}
