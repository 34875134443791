import IGlobalState from '@/shared/store/global/global.interfaces'
import { NavigationState } from '@/shared/store/global/global.state'
import { IDialog } from '@/shared/interfaces/helpers/dialog.interface'
import globalState from '@/shared/store/global/global.state'
import ISnackBar from '@/shared/interfaces/helpers/snack-bar.interface'
import Echo from 'laravel-echo'
import IGallery from '@/shared/interfaces/helpers/gallery.interface'

export enum GlobalMutations {
  setLocale = 'GLOBAL/SET_LOCALE',
  setNavigation = 'GLOBAL/SET_NAVIGATION',
  setLoading = 'GLOBAL/SET_LOADING',
  setDialog = 'GLOBAL/SET_DIALOG',
  setSecondaryDialog = 'GLOBAL/SET_SECONDARY_DIALOG',
  resetDialog = 'GLOBAL/RESET_DIALOG',
  resetSecondaryDialog = 'GLOBAL/RESET_SECONDARY_DIALOG',
  setSnackBar = 'GLOBAL/SET_SNACK_BAR',
  setEchoServerInstance = 'GLOBAL/SET_ECHO_SERVER_INSTANCE',
  setGallery = 'GLOBAL/SET_GALLERY',
  resetGallery = 'GLOBAL/RESET_GALLERY',
  setHeadersHeight = 'GLOBAL/SET_HEADERS_HEIGHT',
}

export default {
  [GlobalMutations.setLocale]: (state: IGlobalState, payload: string): void => {
    state.currentLocale = payload
  },
  [GlobalMutations.setNavigation]: (state: IGlobalState, payload: NavigationState): void => {
    state.navigation = payload
  },
  [GlobalMutations.setLoading]: (state: IGlobalState, payload: boolean): void => {
    state.loading = payload
  },
  [GlobalMutations.setDialog]: (state: IGlobalState, payload: IDialog): void => {
    state.dialog = payload
  },
  [GlobalMutations.setSecondaryDialog]: (state: IGlobalState, payload: IDialog): void => {
    state.secondaryDialog = payload
  },
  [GlobalMutations.resetSecondaryDialog]: (state: IGlobalState): void => {
    state.secondaryDialog = globalState().secondaryDialog
  },
  [GlobalMutations.resetDialog]: (state: IGlobalState): void => {
    state.dialog = globalState().dialog
  },
  [GlobalMutations.setSnackBar]: (state: IGlobalState, payload: ISnackBar | null): void => {
    state.snackBar = payload
  },
  [GlobalMutations.setEchoServerInstance]: (state: IGlobalState, payload: Echo): void => {
    state.echoServerInstance = payload
  },
  [GlobalMutations.setGallery]: (state: IGlobalState, payload: IGallery): void => {
    state.gallery = payload
  },
  [GlobalMutations.resetGallery]: (state: IGlobalState): void => {
    state.gallery = globalState().gallery
  },
  [GlobalMutations.setHeadersHeight]: (state: IGlobalState, payload: number): void => {
    state.headersHeight = payload
  }
}
