














import { Component, Vue } from 'vue-property-decorator'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import NotFound from '@/shared/components/NotFound.vue'
import MultiLanguage from '@/shared/components/MultiLanguage.vue'
import i18n from '@/i18n'
import localeHelper from '@/shared/helpers/locale.helper'
import GlobalDialog from '@/shared/components/GlobalDialog.vue'
import Snackbar from '@/shared/components/Snackbar.vue'
import Gallery from '@/shared/components/Gallery.vue'
import GlobalSecondaryDialog from '@/shared/components/GlobalSecondaryDialog.vue'

@Component({
  components: {
    Snackbar,
    MultiLanguage,
    NotFound,
    Dialog: GlobalDialog,
    SecondaryDialog: GlobalSecondaryDialog,
    Gallery
  }
})
export default class App extends Vue {
  created() {
    i18n.locale = localeHelper.getLocale()
    this.$vuetify.lang.current = localeHelper.getLocale()

    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: 'https://dc4949a63bce46aeb8b489aec6ed905a@o140048.ingest.sentry.io/5397946',
        integrations: [new VueIntegration({Vue, attachProps: true})],
      })
    }
  }

  get isUnauthorized(): boolean {
    return this.$store.getters[AuthGetters.isUnauthorized]
  }
}
