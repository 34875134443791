import IRoutesMeta from '@/shared/interfaces/helpers/routes-meta.interface'
import { AdminRoutes } from '@/shared/router/admin'

const adminRoutesMeta: IRoutesMeta = {
  [AdminRoutes.login]: {
    shouldNotLoggedIn: true,
    redirect: AdminRoutes.companiesIndex
  },
  [AdminRoutes.remindPassword]: {
    shouldNotLoggedIn: true,
    redirect: AdminRoutes.companiesIndex
  },
  [AdminRoutes.resetPassword]: {
    shouldNotLoggedIn: true,
    redirect: AdminRoutes.companiesIndex
  },
  [AdminRoutes.setPassword]: {
    shouldNotLoggedIn: true,
    redirect: AdminRoutes.companiesIndex
  },
  [AdminRoutes.logout]: {},
  [AdminRoutes.usersIndex]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  },
  [AdminRoutes.profile]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  },
  [AdminRoutes.usersCreate]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  },
  [AdminRoutes.usersEdit]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  },
  [AdminRoutes.companiesIndex]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  },
  [AdminRoutes.companiesCreate]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  },
  [AdminRoutes.companiesEdit]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  },
  [AdminRoutes.imports]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  },
  [AdminRoutes.instructionCategories]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  },
  [AdminRoutes.instructionTemplatesIndex]: {
    shouldLoggedIn: true,
    redirect: AdminRoutes.companiesIndex,
  }
}

export default adminRoutesMeta
