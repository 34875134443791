import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import ICountryResponse from '@/shared/modules/country/country-response.interface'

export default class Country extends Model<IModelResponse> {
  name!: string

  transform(data: ICountryResponse): void {
    super.transform(data)

    this.name = data.name
  }
}
