import getRouteMeta from '@/shared/helpers/routes-meta/get-route-meta.helper'
import withPrefix from '@/shared/helpers/with-prefix.helper'

export enum AdminRoutes {
  index = 'admin/index',
  login = 'admin/login',
  remindPassword = 'admin/remind-password',
  resetPassword = 'admin/reset-password',
  setPassword = 'admin/set-password',
  logout = 'admin/logout',
  dashboard = 'admin',
  profile = 'admin/profile',
  usersIndex = 'admin/users',
  usersCreate = 'admin/users/create',
  usersEdit = 'admin/users/edit',
  companiesIndex = 'admin/companies',
  companiesCreate = 'admin/companies/create',
  companiesEdit = 'admin/companies/edit',
  imports = 'admin/imports',
  instructionCategories = 'admin/instruction-categories',
  instructionTemplatesIndex = 'admin/instruction-templates',
  instructionTemplatesCreate = 'admin/instruction-templates/create',
  instructionTemplatesEdit = 'admin/instruction-templates/edit',
  hseRoutineCategories = 'admin/hse/routine-categories',
  hseRoutineTemplatesIndex = 'admin/hse-routine-templates',
  hseRiskCategories = 'admin/hse/risk-categories',
  hseRiskTemplatesIndex = 'admin/hse-risk-templates',
  hseRiskTemplatesCreate = 'admin/hse-risk-templates/create',
  hseRiskTemplatesEdit = 'admin/hse-risk-templates/edit',
  coursesIndex = 'admin/courses',
  coursesCreate = 'admin/courses/create',
  coursesCategories = 'admin/courses/categories',
  courseDetailPage = 'admin/courses/:uuid',
  courseDetailPageOverview = 'admin/courses/:uuid/tab/overview',
  courseDetailPageTest = 'admin/courses/:uuid/tab/test',
  courseDetailPageMaterials = 'admin/courses/:uuid/tab/Materials',
}

export default () => ({
  path: '/admin',
  name: AdminRoutes.index,
  component: () => import('@/admin/Admin.vue'),
  redirect: { name: AdminRoutes.companiesIndex },
  meta: {
    loginRoute: AdminRoutes.login,
  },
  children: [
    {
      path: 'profile',
      name: AdminRoutes.profile,
      component: () => import('@/shared/views/Profile.vue'),
      meta: getRouteMeta(AdminRoutes.profile)
    },
    ...withPrefix('users', [
      {
        path: '/',
        name: AdminRoutes.usersIndex,
        component: () => import('@/admin/views/users/UsersIndex.vue'),
        meta: getRouteMeta(AdminRoutes.usersIndex),
      },
      {
        path: '/create',
        name: AdminRoutes.usersCreate,
        component: () => import('@/admin/views/users/UsersCreate.vue'),
        meta: getRouteMeta(AdminRoutes.usersCreate),
      },
      {
        path: '/:uuid/edit',
        name: AdminRoutes.usersEdit,
        component: () => import('@/admin/views/users/UsersEdit.vue'),
        meta: getRouteMeta(AdminRoutes.usersEdit),
      }
    ]),
    ...withPrefix('companies', [
      {
        path: '/',
        name: AdminRoutes.companiesIndex,
        component: () => import('@/admin/views/companies/CompaniesIndex.vue'),
        meta: getRouteMeta(AdminRoutes.companiesIndex)
      },
      {
        path: '/create',
        name: AdminRoutes.companiesCreate,
        component: () => import('@/admin/views/companies/CompaniesCreate.vue'),
        meta: getRouteMeta(AdminRoutes.companiesCreate),
      },
      {
        path: '/:uuid/edit',
        name: AdminRoutes.companiesEdit,
        component: () => import('@/admin/views/companies/CompaniesEdit.vue'),
        meta: getRouteMeta(AdminRoutes.companiesEdit),
      }
    ]),
    ...withPrefix('courses', [
      {
        path: '/',
        name: AdminRoutes.coursesIndex,
        component: () => import('@/admin/views/courses/CoursesIndex.vue'),
        meta: getRouteMeta(AdminRoutes.coursesIndex),
      },
      {
        path: '/create',
        name: AdminRoutes.coursesCreate,
        component: () => import('@/admin/views/courses/CoursesCreate.vue'),
        meta: getRouteMeta(AdminRoutes.coursesCreate),
      },
      {
        path: '/categories',
        name: AdminRoutes.coursesCategories,
        component: () => import('@/admin/views/courses/CoursesCategoriesIndex.vue'),
        meta: getRouteMeta(AdminRoutes.coursesCategories),
      },
      {
        path: '/:uuid',
        name: AdminRoutes.courseDetailPage,
        component: () => import('@/admin/views/courses/CourseDetailPage/CourseDetailPage.vue'),
        meta: getRouteMeta(AdminRoutes.courseDetailPage),
        children: [
          {
            path: 'overview',
            name: AdminRoutes.courseDetailPageOverview,
            component: () => import('@/admin/views/courses/CourseDetailPage/tabs/Overview.vue'),
            meta: getRouteMeta(AdminRoutes.courseDetailPageOverview),
          },
          {
            path: 'test',
            name: AdminRoutes.courseDetailPageTest,
            component: () => import('@/admin/views/courses/CourseDetailPage/tabs/Test.vue'),
            meta: getRouteMeta(AdminRoutes.courseDetailPageTest),
          },
          {
            path: 'materials',
            name: AdminRoutes.courseDetailPageMaterials,
            component: () => import('@/admin/views/courses/CourseDetailPage/tabs/Materials.vue'),
            meta: getRouteMeta(AdminRoutes.courseDetailPageMaterials),
          }
        ]
      },
    ]),
    ...withPrefix('hse', [
      {
        path: '/routine-categories',
        name: AdminRoutes.hseRoutineCategories,
        component: () => import('@/admin/views/hse/RoutineCategoriesIndex.vue'),
        meta: getRouteMeta(AdminRoutes.hseRoutineCategories)
      },
      ...withPrefix('/routine-templates', [
        {
          path: '/',
          name: AdminRoutes.hseRoutineTemplatesIndex,
          component: () => import('@/admin/views/hse/routine-templates/RoutineTemplatesIndex.vue'),
          meta: getRouteMeta(AdminRoutes.hseRoutineTemplatesIndex)
        }
      ]),
      {
        path: '/risk-categories',
        name: AdminRoutes.hseRiskCategories,
        component: () => import('@/admin/views/hse/RiskCategoriesIndex.vue'),
        meta: getRouteMeta(AdminRoutes.hseRiskCategories)
      },
      ...withPrefix('/risk-templates', [
        {
          path: '/',
          name: AdminRoutes.hseRiskTemplatesIndex,
          component: () => import('@/admin/views/hse/risk-templates/RiskTemplatesIndex.vue'),
          meta: getRouteMeta(AdminRoutes.hseRiskTemplatesIndex)
        },
        {
          path: '/create',
          name: AdminRoutes.hseRiskTemplatesCreate,
          component: () => import('@/admin/views/hse/risk-templates/RiskTemplatesCreate.vue'),
          meta: getRouteMeta(AdminRoutes.hseRiskTemplatesCreate),
        },
        {
          path: '/:uuid/edit',
          name: AdminRoutes.hseRiskTemplatesEdit,
          component: () => import('@/admin/views/hse/risk-templates/RiskTemplateEdit.vue'),
          meta: getRouteMeta(AdminRoutes.hseRiskTemplatesEdit),
        }
      ]),
    ]),
    {
      path: 'instruction-categories',
      name: AdminRoutes.instructionCategories,
      component: () => import('@/admin/views/instruction-categories/InstructionCategoriesIndex.vue'),
      meta: getRouteMeta(AdminRoutes.instructionCategories),
    },
    {
      path: 'instruction-templates',
      name: AdminRoutes.instructionTemplatesIndex,
      component: () => import('@/admin/views/instruction-templates/InstructionTemplatesIndex.vue'),
      meta: getRouteMeta(AdminRoutes.instructionTemplatesIndex),
    },
    {
      path: 'imports',
      name: AdminRoutes.imports,
      component: () => import('@/admin/views/Imports.vue'),
      meta: getRouteMeta(AdminRoutes.imports),
    },
    {
      path: 'login',
      name: AdminRoutes.login,
      component: () => import('@/admin/views/auth/Login.vue'),
      meta: getRouteMeta(AdminRoutes.login),
    },
    {
      path: 'remind-password',
      name: AdminRoutes.remindPassword,
      component: () => import('@/admin/views/auth/RemindPassword.vue'),
      meta: getRouteMeta(AdminRoutes.remindPassword),
    },
    {
      path: 'reset-password/:token/:email',
      name: AdminRoutes.resetPassword,
      component: () => import('@/admin/views/auth/ResetPassword.vue'),
      meta: getRouteMeta(AdminRoutes.resetPassword),
    },
    {
      path: 'set-password/:token/:email',
      name: AdminRoutes.setPassword,
      component: () => import('@/admin/views/auth/SetPassword.vue'),
      meta: getRouteMeta(AdminRoutes.setPassword),
    },
    {
      path: 'logout',
      name: AdminRoutes.logout,
      component: () => import('@/admin/views/auth/Logout.vue'),
      meta: getRouteMeta(AdminRoutes.logout),
    },
  ]
})
