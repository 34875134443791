import Model from '@/shared/classes/model'
import { ITranslatable } from '@/shared/interfaces/classes/model.interfaces'
import IRoleResponse from '@/shared/modules/role/role-response.interface'

export enum RoleLevel {
  superadmin = 1,
  leader = 2,
  manager = 3,
  employee = 4,
  client = 5,
}

export default class Role extends Model<IRoleResponse> {
  level!: number
  name!: ITranslatable<string>|string

  transform(data: IRoleResponse): void {
    super.transform(data)

    this.level = data.level
    this.name = data.name
  }
}
