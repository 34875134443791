import { IAuthState } from '@/shared/store/auth/auth.interfaces'
import User from '@/shared/modules/user/user.model'

export enum AuthGetters {
  isLogged = 'AUTH/IS_LOGGED',
  getUser = 'AUTH/GET_USER',
  isUnauthorized = 'AUTH/IS_UNAUTHORIZED',
  getOtherUsers = 'AUTH/GET_OTHER_USERS',
}

export default {
  [AuthGetters.isLogged]: (state: IAuthState): boolean => !! state.user,
  [AuthGetters.getUser]: (state: IAuthState): User|null => state.user,
  [AuthGetters.isUnauthorized]: (state: IAuthState): boolean => state.unauthorized,
  [AuthGetters.getOtherUsers]: (state: IAuthState): User[] => state.otherUsers,
}
