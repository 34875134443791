import { Route } from 'vue-router'
import tokenHelper from '@/shared/helpers/token.helper'
import store from '@/shared/store'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import User from '@/shared/modules/user/user.model'
import { AuthActions } from '@/shared/store/auth/auth.actions'
import getRouteMeta from '@/shared/helpers/routes-meta/get-route-meta.helper'
import can from '@/shared/helpers/can.helper'

const beforeEach = async (to: Route, from: Route, next: any) => {
  await store.dispatch(AuthActions.setUnauthorized, false)
  const token: string|null = tokenHelper.getToken()
  let isLogged: boolean = store.getters[AuthGetters.isLogged]
  let user: User = store.getters[AuthGetters.getUser]

  if (token && ! isLogged) {
    await store.dispatch(AuthActions.me)
    isLogged = store.getters[AuthGetters.isLogged]
    user = store.getters[AuthGetters.getUser]
  }

  const nextUnauthorized = async () => {
    await store.dispatch(AuthActions.setUnauthorized, true)
    next()
  }

  if (isRouteRequiredToBeLoggedIn(to) && ! isLogged) {
    const loginRoute = to.matched[0].meta.loginRoute

    return loginRoute ? next({ name: loginRoute, params: to.params }) : nextUnauthorized()
  }

  if (isRouteRequiredToBeNotLoggedIn(to) && isLogged) {
    return to.meta.redirect ? next({ name: to.meta.redirect, params: to.params }) : nextUnauthorized()
  }

  if (doesRouteRequirePermission(to)) {
    if (! can(to.meta.permissions)) {
      return to.meta.redirect ? next({ name: to.meta.redirect, params: to.params }) : nextUnauthorized()
    }
  }

  if (checkInitialRedirects(to)) {
    let redirectName: any = null

    to.meta.initialRedirects.forEach((routeName: string) => {
      if (redirectName) return

      const routeMeta = getRouteMeta(routeName)
      if (! routeMeta || ! routeMeta.permissions) {
        redirectName = routeName
        return
      }

      if (can(routeMeta.permissions)) {
        redirectName = routeName
      }
    })

    if (redirectName) {
      return next({ name: redirectName, params: to.params })
    } else {
      return next({ name: to.meta.mainRedirect, params: to.params })
    }
  }

  return next()
}

const isRouteRequiredToBeLoggedIn = (to: Route) => has(to, 'shouldLoggedIn')
const isRouteRequiredToBeNotLoggedIn = (to: Route) => has(to, 'shouldNotLoggedIn')
const doesRouteRequirePermission = (to: Route) => has(to, 'permissions')
const checkInitialRedirects = (to: Route) => to.meta.hasOwnProperty('initialRedirects')
const has = (to: Route, what: string): boolean => to.matched.some((route: any) => route.meta.hasOwnProperty(what))

export default beforeEach
