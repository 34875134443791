import { ActionContext } from 'vuex'
import { ICompanyState } from '@/shared/store/company/company.interfaces'
import CompanyService from '@/shared/modules/company/company.service'
import Company from '@/shared/modules/company/company.model'
import { CompanyMutations } from '@/shared/store/company/company.mutations'

export enum CompanyActions {
  loadStrictCompany = 'COMPANY/LOAD_STRICT',
  loadCurrentCompany = 'COMPANY/LOAD_CURRENT_COMPANY',
  setCompany = 'COMPANY/SET_COMPANY',
}

export default {
  [CompanyActions.loadStrictCompany]: ({ commit }: ActionContext<ICompanyState, any>, payload: string) => {
    commit(CompanyMutations.setLoading, true)
    CompanyService.loadStrict(payload)
      .then((company: Company) => commit(CompanyMutations.setCompany, company))
      .catch(() => commit(CompanyMutations.setLoading, false))
  },
  [CompanyActions.loadCurrentCompany]: ({ commit }: ActionContext<ICompanyState, any>, payload: string) => {
    commit(CompanyMutations.setLoading, true)
    CompanyService.loadCurrentCompany(payload)
      .then((company: Company) => commit(CompanyMutations.setCompany, company))
      .catch(() => commit(CompanyMutations.setLoading, false))
  },
  [CompanyActions.setCompany]: ({ commit }: ActionContext<ICompanyState, any>, payload: Company) => {
    commit(CompanyMutations.setCompany, payload)
  }
}
