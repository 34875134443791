import Service from '@/shared/classes/service'
import User from '@/shared/modules/user/user.model'
import IUserResponse from '@/shared/modules/user/user-response.interface'
import IResponse from '@/shared/interfaces/modules/response.interface'
import tokenHelper from '@/shared/helpers/token.helper'

export default class AuthService extends Service {
  static me(): Promise<User> {
    return this.get<IUserResponse>('/me')
      .then((response: IResponse<IUserResponse>) => new User(response.data))
  }

  static logout(): Promise<any> {
    return this.post<any>('/logout', {})
      .then(response => {
        tokenHelper.clearToken()
        tokenHelper.resetOtherAccessTokens()
        return response
      })
  }
}
