import { ActionContext } from 'vuex'
import { IAuthState } from '@/shared/store/auth/auth.interfaces'
import { AuthMutations } from '@/shared/store/auth/auth.mutations'
import User from '@/shared/modules/user/user.model'
import AuthService from '@/shared/modules/auth/auth.service'
import tokenHelper from '@/shared/helpers/token.helper'
import { IOtherAccessToken } from '@/shared/interfaces/helpers/token.interface'
import UserService from '@/shared/modules/user/user.service'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Echo from 'laravel-echo'
import SocketIOClient from 'socket.io-client'
import { getVersion } from '@/shared/helpers/version.helper'

export enum AuthActions {
  me = 'AUTH/ME',
  logout = 'AUTH/LOGOUT',
  setUnauthorized = 'AUTH/SET_UNAUTHORIZED'
}

export default {
  [AuthActions.me]: async ({ commit, dispatch }: ActionContext<IAuthState, any>) => {
    commit(AuthMutations.resetOtherUsers)
    await tokenHelper.getOtherAccessTokens().map(async (otherAccessToken: IOtherAccessToken) => {
      await UserService.getStrict(otherAccessToken.userUuid)
        .then((user: User) => commit(AuthMutations.addOtherUser, user))
    })

    return AuthService.me()
      .then((user: User) => {
        commit(AuthMutations.setUser, user)
        if (user.defaultLocale && tokenHelper.getOtherAccessTokens().length === 0) { // if not logged in like user
          dispatch(GlobalActions.setLocale, user.defaultLocale)
        }

        const echoUrlBasedOnVersion: any = getVersion() === 'no' ? process.env.VUE_APP_LARAVEL_ECHO_SERVER_URL : process.env.VUE_APP_LARAVEL_ECHO_SERVER_URL_LT

        dispatch(GlobalActions.setEchoServerInstance, new Echo({
          broadcaster: 'socket.io',
          forceTLS: true,
          client: SocketIOClient,
          host: `${ echoUrlBasedOnVersion }/?auth_key=${ process.env.VUE_APP_LARAVEL_ECHO_SERVER_KEY }`,
          auth: {
            headers: {
              Authorization: `Bearer ${ tokenHelper.getToken() }`
            }
          }
        }))

        return user
      })
      .catch(() => {
        tokenHelper.resetOtherAccessTokens()
        tokenHelper.clearToken()
        commit(AuthMutations.reset)
      })
  },
  [AuthActions.logout]: ({ commit, state }: ActionContext<IAuthState, any>) => {
    if (state.user) {
      return AuthService.logout()
        .then(() => {
          commit(AuthMutations.reset)
          return true
        })
        .catch(() => {
          tokenHelper.resetOtherAccessTokens()
          tokenHelper.clearToken()
          commit(AuthMutations.reset)
        })
    }
  },
  [AuthActions.setUnauthorized]: ({ commit }: ActionContext<IAuthState, any>, payload: boolean) => {
    commit(AuthMutations.setUnauthorized, payload)
  }
}
