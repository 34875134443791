import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import tokenHelper from '@/shared/helpers/token.helper'
import localeHelper from '@/shared/helpers/locale.helper'
import store from '@/shared/store'
import { GlobalActions } from '@/shared/store/global/global.actions'
import __ from '@/shared/helpers/__'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import router from '@/shared/router'
import {getVersion} from '@/shared/helpers/version.helper';
import {getApiUrlByVersion} from '@/config';

const http = axios.create({ baseURL: getApiUrlByVersion() })

http.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  if (tokenHelper.getToken()) config.headers['Authorization'] = `Bearer ${ tokenHelper.getToken() }`
  if (!('Accept-Language' in config.headers)) config.headers['Accept-Language'] = localeHelper.getLocale()
  config.headers['X-Region'] = getVersion()
  store.dispatch(GlobalActions.showLoading, true)

  return config
})

http.interceptors.response.use((response: AxiosResponse) => {
  store.dispatch(GlobalActions.showLoading, false)
  return response
}, (error: AxiosError<any>) => {
  let message = __('components.snack-bar.something-wrong')
  if (error.response && error.response.status === 404) throw error
  if (error.response && error.response.status === 422) message = __('components.snack-bar.validation-exception')
  if (error.response && error.response.status === 409) message = __('components.snack-bar.conflict-exception')
  if (error.response && error.response.status === 403) message = __('components.snack-bar.forbidden-exception')
  if (error.response && error.response.status === 401) {
    message = __('components.snack-bar.unauthorized-exception')
    router.go(0)
  }

  store.dispatch(GlobalActions.showSnackBar, {
    type: SnackBarTypes.error,
    message
  })

  store.dispatch(GlobalActions.showLoading, false)
  throw error
})

export default http
