import globalState from '@/shared/store/global/global.state'
import globalGetters from '@/shared/store/global/global.getters'
import globalMutations from '@/shared/store/global/global.mutations'
import globalActions from '@/shared/store/global/global.actions'

export default {
  state: globalState,
  getters: globalGetters,
  mutations: globalMutations,
  actions: globalActions
}
