import { IAuthState } from '@/shared/store/auth/auth.interfaces'
import User from '@/shared/modules/user/user.model'

export enum AuthMutations {
  reset = 'AUTH/RESET',
  setUser = 'AUTH/SET_USER',
  setUnauthorized = 'AUTH/SET_UNAUTHORIZED',
  addOtherUser = 'AUTH/ADD_OTHER_USER',
  resetOtherUsers = 'AUTH/RESET_OTHER_USERS',
}

export default {
  [AuthMutations.setUser]: (state: IAuthState, payload: User): void => {
    state.user = payload
  },
  [AuthMutations.reset]: (state: IAuthState): void => {
    state.user = null
  },
  [AuthMutations.setUnauthorized]: (state: IAuthState, payload: boolean): void => {
    state.unauthorized = payload
  },
  [AuthMutations.addOtherUser]: (state: IAuthState, payload: User): void => {
    state.otherUsers = [ ...state.otherUsers, payload ]
  },
  [AuthMutations.resetOtherUsers]: (state: IAuthState): void => {
    state.otherUsers = []
  }
}
