import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import can from '@/shared/helpers/can.helper'

export default class Model<T> {
  uuid!: string
  id!: number
  createdAt!: string
  deletedAt!: string
  dynamicPermissions!: any
  selected: boolean = false

  constructor(data: T) {
    this.transform(data)
  }

  transform(data: T): void {
    // @ts-ignore
    this.uuid = data.uuid
    // @ts-ignore
    this.createdAt = data.created_at || data.createdAt
    // @ts-ignore
    this.deletedAt = data.deleted_at || data.deletedAt
    // @ts-ignore
    this.dynamicPermissions = data.dynamic_permissions || data.dynamicPermissions
  }

  setRelation(model: Model<IModelResponse> | any, data: IModelResponse|IModelResponse[]): Model<IModelResponse> | Array<Model<IModelResponse>> | undefined | any {
    if (Array.isArray(data)) {
      return data ? data.map(item => new model(item)) : undefined
    }
    return data ? new model(data) : undefined
  }

  hasDynamicPermissions(permissions: string[], atLeastOne: boolean = false): boolean {
    if (! this.dynamicPermissions) return true

    const hasPermission = (permission: string) => {
      const short = permission.split('.')[permission.split('.').length - 1]
      if (this.dynamicPermissions.hasOwnProperty(short)) {
        return !! this.dynamicPermissions[short]
      }

      return can([permission])
    }

    if (atLeastOne) return permissions.some(hasPermission)

    return permissions.every(hasPermission)
  }
}
