import IGlobalState from '@/shared/store/global/global.interfaces'
import { NavigationState } from '@/shared/store/global/global.state'
import { IDialog } from '@/shared/interfaces/helpers/dialog.interface'
import ISnackBar from '@/shared/interfaces/helpers/snack-bar.interface'
import Echo from 'laravel-echo'
import IGallery from '@/shared/interfaces/helpers/gallery.interface'

export enum GlobalGetters {
  getCurrentLocale = 'GLOBAL/GET_LOCALE',
  getNavigation = 'GLOBAL/GET_NAVIGATION',
  getLoading = 'GLOBAL/GET_LOADING',
  getDialog = 'GLOBAL/GET_DIALOG',
  getSecondaryDialog = 'GLOBAL/GET_SECONDARY_DIALOG',
  getSnackBar = 'GLOBAL/GET_SNACK_BAR',
  getEchoServerInstance = 'GLOBAL/GET_ECHO_SERVER_INSTANCE',
  getGallery = 'GLOBAL/GET_GALLERY',
  getHeadersHeight = 'GLOBAL/GET_HEADERS_HEIGHT',
}

export default {
  [GlobalGetters.getCurrentLocale]: (state: IGlobalState): string => state.currentLocale,
  [GlobalGetters.getNavigation]: (state: IGlobalState): NavigationState => state.navigation,
  [GlobalGetters.getLoading]: (state: IGlobalState): boolean => state.loading,
  [GlobalGetters.getDialog]: (state: IGlobalState): IDialog => state.dialog,
  [GlobalGetters.getSecondaryDialog]: (state: IGlobalState): IDialog => state.secondaryDialog,
  [GlobalGetters.getSnackBar]: (state: IGlobalState): ISnackBar | null => state.snackBar,
  [GlobalGetters.getEchoServerInstance]: (state: IGlobalState): Echo | null => state.echoServerInstance,
  [GlobalGetters.getGallery]: (state: IGlobalState): IGallery => state.gallery,
  [GlobalGetters.getHeadersHeight]: (state: IGlobalState): number => state.headersHeight,
}
