import IRoutesMeta from '@/shared/interfaces/helpers/routes-meta.interface'
import { AuthRoutes } from '@/shared/router/auth'

const authRoutesMeta: IRoutesMeta = {
  [AuthRoutes.login]: {
    // shouldNotLoggedIn: true,
  },
  [AuthRoutes.remindPassword]: {
    shouldNotLoggedIn: true,
  },
  [AuthRoutes.setPassword]: {
    shouldNotLoggedIn: true,
  },
  [AuthRoutes.resetPassword]: {
    shouldNotLoggedIn: true,
  },
}

export default authRoutesMeta