import User from '@/shared/modules/user/user.model'
import store from '@/shared/store'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'

const can = (permissions: string[], atLeastOne: boolean = false, entry: Model<IModelResponse>|null = null): boolean => {
  if ( ! permissions) return true

  const user: User = store.getters[AuthGetters.getUser]

  if (entry && entry.hasOwnProperty('dynamicPermissions')) {
    return entry.hasDynamicPermissions(permissions, atLeastOne)
  }

  if ( ! user) return false
  if (user.permissions.includes('*')) return true

  if (atLeastOne) {
    return user.permissions.some((permission: string) => permissions.indexOf(permission) > -1)
  }

  return permissions.every((permission: string) => user.permissions.includes(permission))
}

export default can
