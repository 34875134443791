import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import lt from 'vuetify/src/locale/lt'
import no from 'vuetify/src/locale/no'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple,
  }
});

export default new Vuetify({
  lang: {
    locales: {
      lt,
      no,
    },
    current: 'lt'
  },
  theme: {
    themes: {
      light: {
        primary: '#1638d8',
        secondary: '#2C2D43',
        accent: '#0BC5F9',
        success: '#00941B',
        warning: '#E66F00',
        error: '#F1002B',
      }
    },
    options: {
      customProperties: true,
    },
  }
});
