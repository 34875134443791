import { ICompanyState } from '@/shared/store/company/company.interfaces'
import Company from '@/shared/modules/company/company.model'

export enum CompanyMutations {
  setCompany = 'COMPANY/SET_COMPANY',
  setLoading = 'COMPANY/SET_LOADING'
}

export default {
  [CompanyMutations.setCompany]: (state: ICompanyState, payload: Company): void => {
    state.company = payload
    state.loading = false
  },
  [CompanyMutations.setLoading]: (state: ICompanyState, payload: boolean): void => {
    state.loading = payload
  }
}
