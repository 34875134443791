import {getVersion} from '@/shared/helpers/version.helper';
import { LOCALES } from '@/shared/constants/locales.constants'

export const NO_API_URL = process.env.VUE_APP_API_URL
export const LT_API_URL = process.env.VUE_APP_LT_API_URL
export const NO_API_URL_NODE = process.env.VUE_APP_API_URL_NODE
export const LT_API_URL_NODE = process.env.VUE_APP_API_LT_URL_NODE

export const getApiUrlByVersion = () => {
  return getVersion() === 'no' ? NO_API_URL : LT_API_URL
}

export const getNodeApiUrlByVersion = () => {
  return getVersion() === 'no' ? NO_API_URL_NODE : LT_API_URL_NODE
}

export const getLanguagesByVersion = (exclude: string[] = []) => {
  return Object.keys(LOCALES)
    .filter((key: string) => {
      return LOCALES[key].inVersions.some((version: string) => getVersion() === version) &&
        !exclude.includes(key)
    })
}

export const getAdditionalLanguagesByVersion = (exclude: string[] = []) => {
  return Object.keys(LOCALES)
    .filter((key: string) => {
      return LOCALES[key].additionalInVersions.some((version: string) => getVersion() === version) &&
        !exclude.includes(key)
    })
}
