import authState from '@/shared/store/auth/auth.state'
import authGetters from '@/shared/store/auth/auth.getters'
import authMutations from '@/shared/store/auth/auth.mutations'
import authActions from '@/shared/store/auth/auth.actions'

export default {
  state: authState,
  getters: authGetters,
  mutations: authMutations,
  actions: authActions
}
