const permissions = {
  attachments: {
    rotate: 'attachments.rotate',
    edit: 'attachments.edit',
    update: 'attachments.update',
  },
  admin: {
    users: {
      loginLike: 'users.login-like',
      deactivate: 'users.deactivate',
    }
  },
  company: {
    projects: {
      index: 'projects.index',
      show: 'projects.show',
      create: 'projects.store',
      update: 'projects.update',
      delete: 'projects.destroy',
      copy: 'projects.copy',
      seeChecklists: 'projects.see-checklists',
      summary: 'projects.summary',
    },
    summary: {
    },
    projectEmployees: {
      index: 'project-employees.index',
      create: 'project-employees.store',
      update: 'project-employees.update',
      delete: 'project-employees.destroy',
    },
    employees: {
      index: 'employees.index',
      show: 'employees.show',
      create: 'employees.store',
      update: 'employees.update',
      delete: 'employees.destroy',
      deactivate: 'employees.deactivate',
      loginLike: 'employees.login-like',
      updateEmail: 'employees.update-email'
    },
    clients: {
      index: 'clients.index',
      show: 'clients.show',
      create: 'clients.store',
      update: 'clients.update',
      delete: 'clients.destroy',
    },
    tasks: {
      index: 'tasks.index',
      show: 'tasks.show',
      create: 'tasks.store',
      update: 'tasks.update',
      delete: 'tasks.destroy',
      toggleConfirm: 'tasks.toggle-confirm',
      massConfirm: 'tasks.mass-confirm',
    },
    taskTypes: {
      index: 'task-types.index',
      show: 'task-types.show',
      create: 'task-types.store',
      update: 'task-types.update',
      delete: 'task-types.destroy',
    },
    violations: {
      index: 'violations.index',
      show: 'violations.show',
      create: 'violations.store',
      update: 'violations.update',
      delete: 'violations.destroy',
      getAttachments: 'violations.get-attachments',
      uploadAttachments: 'violations.upload-attachments',
      deleteAttachment: 'violations.delete-attachment',
    },
    groups: {
      index: 'groups.index',
      show: 'groups.show',
      create: 'groups.store',
      update: 'groups.update',
      delete: 'groups.destroy',
      upload: 'groups.upload',
      downloadAttachment: 'groups.attachments-download',
      deleteAttachment: 'groups.attachments-delete',
      rotateAttachment: 'groups.attachments-rotate',
      editAttachment: 'groups.attachments-edit',
      attachmentsMassDownload: 'groups.attachments-mass-download',
      attachmentsMassDelete: 'groups.attachments-mass-delete',
      generatePdf: 'groups.generate-pdf',
      createChecklist: 'groups.create-checklist',
      dragAndDrop: 'groups.drag-and-drop',
      tree: 'groups.tree',
    },
    systemicGroups: {
      index: 'systemic-groups.index',
    },
    checklists: {
      index: 'checklists.index',
      show: 'checklists.show',
      create: 'checklists.store',
      update: 'checklists.update',
      delete: 'checklists.destroy',
      attachments: 'checklists.attachments',
      downloadAttachment: 'checklists.download-attachment',
      report: 'checklists.report',
    },
    checkpoints: {
      index: 'checkpoints.index',
      show: 'checkpoints.show',
      create: 'checkpoints.store',
      update: 'checkpoints.update',
      delete: 'checkpoints.destroy',
      attachments: 'checkpoints.attachments',
      uploadAttachments: 'checkpoints.upload-attachments',
      downloadAttachment: 'checkpoints.download-attachment',
      deleteAttachment: 'checkpoints.delete-attachment',
      fill: 'checkpoints.fill',
      reorder: 'checkpoints.reorder',
      updateStatus: 'checkpoints.update-status'
    },
    reports: {
      index: 'reports.index',
      allHours: 'reports.all-hours',
      paidHours: 'reports.paid-hours',
      collectedHours: 'reports.collected-hours',
      projects: 'reports.projects',
    },
    info: 'companies.update-current-company',
    deactivate: 'companies.deactivate',
    activate: 'companies.activate',
    permissions: {
      index: 'permissions.get-company',
      update: 'permissions.update-company',
    },
    tools: {
      index: 'tools.index',
      show: 'tools.show',
      store: 'tools.store',
      update: 'tools.update',
      destroy: 'tools.destroy',
      move: 'tools.move',
      discard: 'tools.discard',

      getAttachments: 'tools.get-attachments',
      uploadAttachments: 'tools.upload-attachments',
      deleteAttachment: 'tools.delete-attachment',
    },
    toolCategories: {
      index: 'tool-categories.index',
      show: 'tool-categories.show',
      store: 'tool-categories.store',
      update: 'tool-categories.update',
      destroy: 'tool-categories.destroy',
      deactivate: 'tool-categories.deactivate',
    },
    toolLocations: {
      index: 'tool-locations.index',
      show: 'tool-locations.show',
      store: 'tool-locations.store',
      update: 'tool-locations.update',
      destroy: 'tool-locations.destroy',
      deactivate: 'tool-locations.deactivate',
    },
    courses: {
      overview: 'companies.overview',
    },
    riskAssessments: {
      show: 'riskAssessments.show',
      create: 'riskAssessments.store',
      update: 'riskAssessments.update',
      delete: 'riskAssessments.destroy',
    }
  }
}

export default permissions
