import 'babel-polyfill'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/shared/router'
import store from '@/shared/store'
import vuetify from '@/shared/plugins/vuetify'
import '@/styles/app.scss'
import '@/styles/overrides.scss'
import i18n from './i18n'
import * as VueGoogleMaps from 'vue2-google-maps'
import { GlobalGetters } from '@/shared/store/global/global.getters'

Vue.config.productionTip = false

// @ts-ignore
Vue.$router = router

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places',
    language: store.getters[GlobalGetters.getCurrentLocale],
  },
  installComponents: true
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
