import IGlobalState from '@/shared/store/global/global.interfaces'
import localeHelper from '@/shared/helpers/locale.helper'

export enum NavigationState {
  default = 'default',
  minimized = 'minimized'
}

export default (): IGlobalState => ({
  currentLocale: localeHelper.getLocale(),
  navigation: NavigationState.default,
  loading: false,
  dialog: {
    show: false,
    component: null,
    maxWidth: 350,
    persistent: false,
  },
  secondaryDialog: {
    show: false,
    component: null,
    maxWidth: 350,
    persistent: false,
  },
  snackBar: null,
  echoServerInstance: null,
  gallery: {
    show: false,
    attachments: [],
    initialSlideIndex: null,
  },
  headersHeight: 55,
})
