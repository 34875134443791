import Model from '@/shared/classes/model'
import ICompanyResponse from '@/shared/modules/company/company-response.interface'
import Country from '@/shared/modules/country/country.model'
import IWorkHoursPerDayChanges from '@/shared/modules/company/work-hours-per-day-changes.interface'

export default class Company extends Model<ICompanyResponse> {
  name!: string
  slug!: string
  description!: string
  companyCode!: string
  vatCode!: string
  address!: string
  postCode!: string
  city!: string
  website!: string
  phone!: string
  logo!: string
  breakMinutes!: number | string
  workHoursPerDay!: number
  workHoursPerDayChanges!: IWorkHoursPerDayChanges | null
  workHoursPerDayOnWeekend!: number
  workHoursPerDayOnWeekendChanges!: IWorkHoursPerDayChanges | null
  timeRegistrationAvailability!: number
  deactivatedAt!: string
  isToolsVisible!: boolean
  isHseVisible!: boolean
  isTimeAccountingVisible!: boolean
  warehouseResponsibleUuid!: string|null
  hseCeoUserUuid!: string|null

  countryUuid!: string
  country!: Country

  transform(data: ICompanyResponse): void {
    super.transform(data)

    this.name = data.name
    this.slug = data.slug
    this.description = data.description
    this.companyCode = data.company_code
    this.vatCode = data.vat_code
    this.address = data.address
    this.postCode = data.post_code
    this.city = data.city
    this.website = data.website
    this.phone = data.phone
    this.logo = data.logo
    this.breakMinutes = data.break_minutes === 0 ? '0' : data.break_minutes
    this.workHoursPerDay = data.work_hours_per_day
    this.workHoursPerDayChanges = data.work_hours_per_day_changes
    this.workHoursPerDayOnWeekend = data.work_hours_per_day_on_weekend
    this.workHoursPerDayOnWeekendChanges = data.work_hours_per_day_on_weekend_changes
    this.timeRegistrationAvailability = data.time_registration_availability
    this.deactivatedAt = data.deactivated_at
    this.isToolsVisible = data.is_tools_visible
    this.isHseVisible = data.is_hse_visible
    this.isTimeAccountingVisible = data.is_time_accounting_visible
    this.warehouseResponsibleUuid = data.warehouse_responsible_uuid
    this.hseCeoUserUuid = data.hse_ceo_user_uuid

    this.countryUuid = data.country_uuid
    this.country = this.setRelation(Country, data.country)
  }
}
