import getRouteMeta from '@/shared/helpers/routes-meta/get-route-meta.helper'
import withPrefix from '@/shared/helpers/with-prefix.helper'
import CourseMaterials from "@/company/views/courses/CourseMaterials.vue";

export enum CompanyRoutes {
  index = 'company/index',
  dashboard = 'company/dashboard',
  profile = 'company/profile',
  login = 'company/login',
  logout = 'company/logout',
  remindPassword = 'company/remind-password',
  resetPassword = 'company/reset-password',
  setPassword = 'company/set-password',

  info = 'company/info',

  taskTypesIndex = 'company/task-types',
  taskTypesCreate = 'company/task-types/create',
  taskTypesEdit = 'company/task-types/edit',

  employeesIndex = 'company/employees',
  employeesCreate = 'company/employees/create',
  employeesEdit = 'company/employees/edit',

  clientsIndex = 'company/clients',
  clientsCreate = 'company/clients/create',
  clientsEdit = 'company/clients/edit',

  projectsIndex = 'company/projects',
  projectsShow = 'company/projects/show',
  projectsShowTabIndex = 'company/projects/show/tab/index',
  projectsShowTabDocuments = 'company/projects/show/tab/documents',
  projectsShowTabReports = 'company/projects/show/tab/reports',
  projectsShowTabTasksAndTime = 'company/projects/show/tab/tasks-and-time',
  projectsShowTabViolations = 'company/projects/show/tab/violations',
  projectsShowTabChecklists = 'company/projects/show/tab/checklists',
  projectsShowTabEmployees = 'company/projects/show/tab/employees',

  projectsShowTabSummary = 'company/projects/show/tab/summary',
  projectsShowTabSummaryTabInstructions = 'company/projects/show/tab/summary/tab/instructions',
  projectsShowTabSummaryTabInstructionsAssigned = 'company/projects/show/tab/summary/tab/instructions/assigned',
  projectsShowTabSummaryTabInstructionsTemplates = 'company/projects/show/tab/summary/tab/instructions/templates',
  projectsShowTabSummaryTabSummaryGeneration = 'company/projects/show/tab/summary/tab/summary-generation',

  projectsCreate = 'company/projects/create',
  projectsEdit = 'company/projects/edit',

  tasksIndex = 'company/tasks',
  violationsIndex = 'company/violations',

  checklistsIndex = 'company/checklists',
  checklistsShow = 'company/checklists/show',

  reportsIndex = 'company/reports',
  reportsIndexTabAllHours = 'company/reports/tab/all-hours',
  reportsIndexTabPaidHours = 'company/reports/tab/paid-hours',
  reportsIndexTabCollectedHours = 'company/reports/tab/collected-hours',
  reportsIndexTabProjects = 'company/reports/tab/projects',

  permissions = 'company/permissions',

  toolsIndex = 'company/tools',
  toolsShow = 'company/tools/show',
  toolsShowTabIndex = 'company/tools/show/tab/index',
  toolsCreate = 'company/tools/create',
  toolsEdit = 'company/tools/edit',
  toolsMove = 'company/tools/move',
  toolsDiscarded = 'company/tools/discarded',

  toolCategoriesIndex = 'company/tool-categories',
  toolCategoriesCreate = 'company/tool-categories/create',
  toolCategoriesEdit = 'company/tool-categories/edit',

  toolLocationsIndex = 'company/tool-locations',
  toolLocationsCreate = 'company/tool-locations/create',
  toolLocationsEdit = 'company/tool-locations/edit',

  coursesIndex = 'company/courses/overview',
  coursesTestIndex = 'company/courses/test',
  coursesMaterialsIndex = 'company/courses/materials',
  coursesStatistics = 'company/courses/statistics',

  riskAssessmentsIndex = 'company/risk-assessments',
  riskAssessmentsIndexTabAssigned = 'company/risk-assessments/tab/assigned',
  riskAssessmentsIndexTabTemplates = 'company/risk-assessments/tab/templates',
  riskAssessmentsCreate = 'company/risk-assessments/create',
  riskAssessmentsEdit = 'company/risk-assessments/edit',
  assignedRiskCreate = 'company/risk-assessments/assigned/create',
  assignedRiskEdit = 'company/risk-assessments/assigned/edit',
  assignedRiskView = 'company/risk-assessments/assigned/view',

  hseRoutinesIndex = 'company/hse-routines',
  hseRoutinesIndexTabAssigned = 'company/hse-routines/tab/assigned',
  hseRoutinesIndexTabTemplates = 'company/hse-routines/tab/templates',
  hseRoutineTemplateCreate = 'company/hse-routines/template/create',
  hseRoutineTemplateEdit = 'company/hse-routines/template/edit',
  assignedRoutineCreate = 'company/hse-routines/assigned/create',
  assignedRoutineEdit = 'company/hse-routines/assigned/edit',
  assignedRoutineView = 'company/hse-routines/assigned/view',

  hseHandbookIndex = 'company/hse-handbook',
  hseHandbookSigning = 'company/hse-handbook-signing',
  orgStructureIndex = 'company/org-structure',
  hseViolationsIndex = 'company/hse-violations'
}

export default () => ({
  path: '/:company',
  name: CompanyRoutes.index,
  component: () => import('@/company/Company.vue'),
  redirect: { name: CompanyRoutes.projectsIndex },
  meta: {
    loginRoute: CompanyRoutes.login,
  },
  children: [
    // {
    //   path: '/',
    //   name: CompanyRoutes.dashboard,
    //   component: () => import('@/company/views/Dashboard.vue'),
    //   meta: getRouteMeta(CompanyRoutes.dashboard)
    // },
    {
      path: 'profile',
      name: CompanyRoutes.profile,
      component: () => import('@/shared/views/Profile.vue'),
      meta: getRouteMeta(CompanyRoutes.profile),
    },
    ...withPrefix('projects', [
      {
        path: '/',
        name: CompanyRoutes.projectsIndex,
        component: () => import('@/company/views/projects/ProjectsIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.projectsIndex),
      },
      {
        path: '/create',
        name: CompanyRoutes.projectsCreate,
        component: () => import('@/company/views/projects/ProjectsCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.projectsCreate),
      },
      {
        path: '/:uuid',
        name: CompanyRoutes.projectsShow,
        component: () => import('@/company/views/projects/ProjectsShow.vue'),
        meta: getRouteMeta(CompanyRoutes.projectsShow),
        redirect: { name: CompanyRoutes.projectsShowTabIndex },
        children: [
          {
            path: 'show',
            name: CompanyRoutes.projectsShowTabIndex,
            component: () => import('@/company/views/projects/projects-show/Index.vue'),
            meta: getRouteMeta(CompanyRoutes.projectsShowTabIndex),
          },
          {
            path: 'documents',
            name: CompanyRoutes.projectsShowTabDocuments,
            component: () => import('@/company/views/projects/projects-show/Documents.vue'),
            meta: getRouteMeta(CompanyRoutes.projectsShowTabDocuments),
          },
          {
            path: 'reports',
            name: CompanyRoutes.projectsShowTabReports,
            component: () => import('@/company/views/projects/projects-show/Reports.vue'),
            meta: getRouteMeta(CompanyRoutes.projectsShowTabReports),
          },
          {
            path: 'tasks-and-time',
            name: CompanyRoutes.projectsShowTabTasksAndTime,
            component: () => import('@/company/views/projects/projects-show/TasksAndTime.vue'),
            meta: getRouteMeta(CompanyRoutes.projectsShowTabTasksAndTime),
          },
          {
            path: 'violations',
            name: CompanyRoutes.projectsShowTabViolations,
            component: () => import('@/company/views/projects/projects-show/Violations.vue'),
            meta: getRouteMeta(CompanyRoutes.projectsShowTabViolations),
          },
          {
            path: 'checklists',
            name: CompanyRoutes.projectsShowTabChecklists,
            component: () => import('@/company/views/projects/projects-show/Checklists.vue'),
            meta: getRouteMeta(CompanyRoutes.projectsShowTabChecklists),
          },
          {
            path: 'employees',
            name: CompanyRoutes.projectsShowTabEmployees,
            component: () => import('@/company/views/projects/projects-show/Employees.vue'),
            meta: getRouteMeta(CompanyRoutes.projectsShowTabEmployees),
          },
          ...withPrefix('summary', [
            {
              path: '/',
              name: CompanyRoutes.projectsShowTabSummary,
              component: () => import('@/company/views/projects/projects-show/Summary.vue'),
              meta: getRouteMeta(CompanyRoutes.projectsShowTabSummary),
              children: [
                {
                  path: 'instructions',
                  name: CompanyRoutes.projectsShowTabSummaryTabInstructions,
                  component: () => import('@/company/views/projects/projects-show/Instructions.vue'),
                  meta: getRouteMeta(CompanyRoutes.projectsShowTabSummaryTabInstructions),
                  children: [
                    {
                      path: 'assigned',
                      name: CompanyRoutes.projectsShowTabSummaryTabInstructionsAssigned,
                      component: () => import('@/company/views/projects/projects-show/AssignedInstructions.vue'),
                      meta: getRouteMeta(CompanyRoutes.projectsShowTabSummaryTabInstructionsAssigned),
                    },
                    {
                      path: 'templates',
                      name: CompanyRoutes.projectsShowTabSummaryTabInstructionsTemplates,
                      component: () => import('@/company/views/projects/projects-show/CompanyInstructionTemplates.vue'),
                      meta: getRouteMeta(CompanyRoutes.projectsShowTabSummaryTabInstructionsTemplates),
                    },
                  ],
                },
                {
                  path: 'summary-generation',
                  name: CompanyRoutes.projectsShowTabSummaryTabSummaryGeneration,
                  component: () => import('@/company/views/projects/projects-show/SummaryGeneration.vue'),
                  meta: getRouteMeta(CompanyRoutes.projectsShowTabSummaryTabSummaryGeneration),
                },
              ],
            },
          ]),
        ],
      },
      {
        path: '/:uuid/edit',
        name: CompanyRoutes.projectsEdit,
        component: () => import('@/company/views/projects/ProjectsEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.projectsEdit),
      },
    ]),
    ...withPrefix('tasks-and-time', [
      {
        path: '/',
        name: CompanyRoutes.tasksIndex,
        component: () => import('@/company/views/tasks/TasksIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.tasksIndex),
      },
    ]),
    ...withPrefix('violations', [
      {
        path: '/',
        name: CompanyRoutes.violationsIndex,
        component: () => import('@/company/views/violations/ViolationsIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.violationsIndex),
      },
    ]),
    ...withPrefix('checklists', [
      {
        path: '/',
        name: CompanyRoutes.checklistsIndex,
        component: () => import('@/company/views/checklists/ChecklistsIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.checklistsIndex),
      },
      {
        path: '/:uuid',
        name: CompanyRoutes.checklistsShow,
        component: () => import('@/company/views/checklists/ChecklistsShow.vue'),
        meta: getRouteMeta(CompanyRoutes.checklistsShow),
      },
    ]),
    ...withPrefix('reports', [
      {
        path: '/',
        name: CompanyRoutes.reportsIndex,
        component: () => import('@/company/views/reports/ReportsIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.reportsIndex),
        redirect: { name: CompanyRoutes.reportsIndexTabPaidHours },
        children: [
          {
            path: 'all-hours',
            name: CompanyRoutes.reportsIndexTabAllHours,
            component: () => import('@/company/views/reports/index/AllHours.vue'),
            meta: getRouteMeta(CompanyRoutes.reportsIndexTabAllHours),
          },
          {
            path: 'paid-hours',
            name: CompanyRoutes.reportsIndexTabPaidHours,
            component: () => import('@/company/views/reports/index/PaidHours.vue'),
            meta: getRouteMeta(CompanyRoutes.reportsIndexTabPaidHours),
          },
          {
            path: 'collected-hours',
            name: CompanyRoutes.reportsIndexTabCollectedHours,
            component: () => import('@/company/views/reports/index/CollectedHours.vue'),
            meta: getRouteMeta(CompanyRoutes.reportsIndexTabCollectedHours),
          },
          {
            path: 'projects',
            name: CompanyRoutes.reportsIndexTabProjects,
            component: () => import('@/company/views/reports/index/Projects.vue'),
            meta: getRouteMeta(CompanyRoutes.reportsIndexTabProjects),
          },
        ],
      },
    ]),
    ...withPrefix('employees', [
      {
        path: '/',
        name: CompanyRoutes.employeesIndex,
        component: () => import('@/company/views/employees/EmployeesIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.employeesIndex),
      },
      {
        path: '/create',
        name: CompanyRoutes.employeesCreate,
        component: () => import('@/company/views/employees/EmployeesCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.employeesCreate),
      },
      {
        path: '/:uuid/edit',
        name: CompanyRoutes.employeesEdit,
        component: () => import('@/company/views/employees/EmployeesEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.employeesEdit),
      },
    ]),
    ...withPrefix('clients', [
      {
        path: '/',
        name: CompanyRoutes.clientsIndex,
        component: () => import('@/company/views/clients/ClientsIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.clientsIndex),
      },
      {
        path: '/create',
        name: CompanyRoutes.clientsCreate,
        component: () => import('@/company/views/clients/ClientsCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.clientsCreate),
      },
      {
        path: '/:uuid/edit',
        name: CompanyRoutes.clientsEdit,
        component: () => import('@/company/views/clients/ClientsEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.clientsEdit),
      },
    ]),
    ...withPrefix('task-types', [
      {
        path: '/',
        name: CompanyRoutes.taskTypesIndex,
        component: () => import('@/company/views/task-types/TaskTypesIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.taskTypesIndex),
      },
      {
        path: '/create',
        name: CompanyRoutes.taskTypesCreate,
        component: () => import('@/company/views/task-types/TaskTypesCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.taskTypesCreate),
      },
      {
        path: '/:uuid/edit',
        name: CompanyRoutes.taskTypesEdit,
        component: () => import('@/company/views/task-types/TaskTypesEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.taskTypesEdit),
      },
    ]),
    ...withPrefix('tools', [
      {
        path: '/',
        name: CompanyRoutes.toolsIndex,
        component: () => import('@/company/views/tools/ToolsIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.toolsIndex),
      },
      {
        path: '/create',
        name: CompanyRoutes.toolsCreate,
        component: () => import('@/company/views/tools/ToolsCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.toolsCreate),
      },
      {
        path: '/move',
        name: CompanyRoutes.toolsMove,
        component: () => import('@/company/views/tools/ToolsMove.vue'),
        meta: getRouteMeta(CompanyRoutes.toolsMove),
      },
      {
        path: '/discarded',
        name: CompanyRoutes.toolsDiscarded,
        component: () => import('@/company/views/tools/ToolsDiscarded.vue'),
        meta: getRouteMeta(CompanyRoutes.toolsDiscarded),
      },
      {
        path: '/:uuid',
        name: CompanyRoutes.toolsShow,
        component: () => import('@/company/views/tools/ToolsShow.vue'),
        meta: getRouteMeta(CompanyRoutes.toolsShow),
        redirect: { name: CompanyRoutes.toolsShowTabIndex },
        children: [
          {
            path: 'show',
            name: CompanyRoutes.toolsShowTabIndex,
            component: () => import('@/company/views/projects/projects-show/Index.vue'),
            meta: getRouteMeta(CompanyRoutes.toolsShowTabIndex),
          },
        ],
      },
      {
        path: '/:uuid/edit',
        name: CompanyRoutes.toolsEdit,
        component: () => import('@/company/views/tools/ToolsEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.toolsEdit),
      },
    ]),
    ...withPrefix('tool-categories', [
      {
        path: '/',
        name: CompanyRoutes.toolCategoriesIndex,
        component: () => import('@/company/views/tool-categories/ToolCategoriesIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.toolCategoriesIndex),
      },
      {
        path: '/create',
        name: CompanyRoutes.toolCategoriesCreate,
        component: () => import('@/company/views/tool-categories/ToolCategoriesCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.toolCategoriesCreate),
      },
      {
        path: '/:uuid/edit',
        name: CompanyRoutes.toolCategoriesEdit,
        component: () => import('@/company/views/tool-categories/ToolCategoriesEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.toolCategoriesEdit),
      },
    ]),
    ...withPrefix('tool-locations', [
      {
        path: '/',
        name: CompanyRoutes.toolLocationsIndex,
        component: () => import('@/company/views/tool-locations/ToolLocationsIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.toolLocationsIndex),
      },
      {
        path: '/create',
        name: CompanyRoutes.toolLocationsCreate,
        component: () => import('@/company/views/tool-locations/ToolLocationsCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.toolLocationsCreate),
      },
      {
        path: '/:uuid/edit',
        name: CompanyRoutes.toolLocationsEdit,
        component: () => import('@/company/views/tool-locations/ToolLocationsEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.toolLocationsEdit),
      },
    ]),
    {
      path: 'info',
      name: CompanyRoutes.info,
      component: () => import('@/company/views/Info.vue'),
      meta: getRouteMeta(CompanyRoutes.info),
    },
    {
      path: 'permissions',
      name: CompanyRoutes.permissions,
      component: () => import('@/company/views/Permissions.vue'),
      meta: getRouteMeta(CompanyRoutes.permissions),
    },
    {
      path: 'login',
      name: CompanyRoutes.login,
      component: () => import('@/company/views/auth/Login.vue'),
      meta: getRouteMeta(CompanyRoutes.login),
    },
    {
      path: 'remind-password',
      name: CompanyRoutes.remindPassword,
      component: () => import('@/company/views/auth/RemindPassword.vue'),
      meta: getRouteMeta(CompanyRoutes.remindPassword),
    },
    {
      path: 'reset-password/:token/:email',
      name: CompanyRoutes.resetPassword,
      component: () => import('@/company/views/auth/ResetPassword.vue'),
      meta: getRouteMeta(CompanyRoutes.resetPassword),
    },
    {
      path: 'set-password/:token/:email',
      name: CompanyRoutes.setPassword,
      component: () => import('@/company/views/auth/SetPassword.vue'),
      meta: getRouteMeta(CompanyRoutes.setPassword),
    },
    {
      path: 'logout',
      name: CompanyRoutes.logout,
      component: () => import('@/company/views/auth/Logout.vue'),
      meta: getRouteMeta(CompanyRoutes.logout),
    },
    ...withPrefix('risk-assessments', [
      {
        path: '/',
        name: CompanyRoutes.riskAssessmentsIndex,
        component: () => import('@/company/views/risk-assessments/RiskAssessmentsIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.riskAssessmentsIndex),
        children: [
          {
            path: 'assigned',
            name: CompanyRoutes.riskAssessmentsIndexTabAssigned,
            component: () => import('@/company/views/risk-assessments/tabs/AssignedRiskAssessments.vue'),
            meta: getRouteMeta(CompanyRoutes.riskAssessmentsIndexTabAssigned),
          },
          {
            path: 'templates',
            name: CompanyRoutes.riskAssessmentsIndexTabTemplates,
            component: () => import('@/company/views/risk-assessments/tabs/TemplatesRiskAssessments.vue'),
            meta: getRouteMeta(CompanyRoutes.riskAssessmentsIndexTabTemplates),
          },
        ],
      },
      {
        path: '/create',
        name: CompanyRoutes.riskAssessmentsCreate,
        component: () => import('@/company/views/risk-assessments/RiskAssessmentsCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.riskAssessmentsCreate),
      },
      {
        path: '/:uuid/edit',
        name: CompanyRoutes.riskAssessmentsEdit,
        component: () => import('@/company/views/risk-assessments/RiskAssessmentsEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.riskAssessmentsEdit),
      },
      {
        path: '/assigned/create',
        name: CompanyRoutes.assignedRiskCreate,
        component: () => import('@/company/views/risk-assessments/AssignedRiskCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.assignedRiskCreate),
      },
      {
        path: '/assigned/:uuid/edit',
        name: CompanyRoutes.assignedRiskEdit,
        component: () => import('@/company/views/risk-assessments/AssignedRiskEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.assignedRiskEdit),
      },
      {
        path: '/assigned/:uuid',
        name: CompanyRoutes.assignedRiskView,
        component: () => import('@/company/views/risk-assessments/AssignedRiskView.vue'),
        meta: getRouteMeta(CompanyRoutes.assignedRiskView),
      },
    ]),
    ...withPrefix('hse-routines', [
      {
        path: '/',
        name: CompanyRoutes.hseRoutinesIndex,
        component: () => import('@/company/views/hse-routines/HseRoutinesIndex.vue'),
        meta: getRouteMeta(CompanyRoutes.hseRoutinesIndex),
        children: [
          {
            path: 'assigned',
            name: CompanyRoutes.hseRoutinesIndexTabAssigned,
            component: () => import('@/company/views/hse-routines/tabs/AssignedHseRoutines.vue'),
            meta: getRouteMeta(CompanyRoutes.hseRoutinesIndexTabAssigned),
          },
          {
            path: 'templates',
            name: CompanyRoutes.hseRoutinesIndexTabTemplates,
            component: () => import('@/company/views/hse-routines/tabs/TemplatesHseRoutines.vue'),
            meta: getRouteMeta(CompanyRoutes.hseRoutinesIndexTabTemplates),
          },
        ],
      },
      {
        path: '/template/create',
        name: CompanyRoutes.hseRoutineTemplateCreate,
        component: () => import('@/company/views/hse-routines/TemplateRoutineCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.hseRoutineTemplateCreate),
      },
      {
        path: '/template/:uuid/edit',
        name: CompanyRoutes.hseRoutineTemplateEdit,
        component: () => import('@/company/views/hse-routines/TemplateRoutineEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.hseRoutineTemplateEdit),
      },
      {
        path: '/assigned/create',
        name: CompanyRoutes.assignedRoutineCreate,
        component: () => import('@/company/views/hse-routines/AssignedRoutineCreate.vue'),
        meta: getRouteMeta(CompanyRoutes.assignedRoutineCreate),
      },
      {
        path: '/assigned/:uuid/edit',
        name: CompanyRoutes.assignedRoutineEdit,
        component: () => import('@/company/views/hse-routines/AssignedRoutineEdit.vue'),
        meta: getRouteMeta(CompanyRoutes.assignedRoutineEdit),
      },
      {
        path: '/assigned/:uuid',
        name: CompanyRoutes.assignedRoutineView,
        component: () => import('@/company/views/hse-routines/AssignedRoutineView.vue'),
        meta: getRouteMeta(CompanyRoutes.assignedRoutineView),
      },
    ]),
    {
      path: 'hse-handbook',
      name: CompanyRoutes.hseHandbookIndex,
      component: () => import('@/company/views/hse-handbook/HseHandbookIndex.vue'),
      meta: getRouteMeta(CompanyRoutes.hseHandbookIndex),
    },
    ...withPrefix('hse-handbook-signing', [
      {
        path: '/:uuid',
        name: CompanyRoutes.hseHandbookSigning,
        component: () => import('@/company/views/hse-handbook/AcknowledgementSigningHseHandbook.vue'),
        meta: getRouteMeta(CompanyRoutes.hseHandbookSigning),
      },
    ]),
    {
      path: 'org-structure',
      name: CompanyRoutes.orgStructureIndex,
      component: () => import('@/company/views/org-structure/OrgStructureIndex.vue'),
      meta: getRouteMeta(CompanyRoutes.orgStructureIndex),
    }, {
      path: 'hse-violations',
      name: CompanyRoutes.hseViolationsIndex,
      component: () => import('@/company/views/hse-violations/HseViolationsIndex.vue'),
      meta: getRouteMeta(CompanyRoutes.hseViolationsIndex),
    },
    {
      path: 'assigned-courses',
      name: CompanyRoutes.coursesIndex,
      component: () => import('@/company/views/courses/AssignedCourses.vue'),
      meta: getRouteMeta(CompanyRoutes.coursesIndex),
    },
    {
      path: 'courses-test/:uuid',
      name: CompanyRoutes.coursesTestIndex,
      component: () => import('@/company/views/courses/CourseTest.vue'),
      meta: getRouteMeta(CompanyRoutes.coursesTestIndex),
    },
    {
      path: 'courses-materials/:uuid',
      name: CompanyRoutes.coursesMaterialsIndex,
      component: () => import('@/company/views/courses/CourseMaterials.vue'),
      meta: getRouteMeta(CompanyRoutes.coursesMaterialsIndex),
    },
    {
      path: 'course-statistics',
      name: CompanyRoutes.coursesStatistics,
      component: () => import('@/company/views/courses/CourseStatistics.vue'),
      meta: getRouteMeta(CompanyRoutes.coursesStatistics),
    },
  ],
})
