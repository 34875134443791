import { ActionContext } from 'vuex'
import IGlobalState from '@/shared/store/global/global.interfaces'
import { GlobalMutations } from '@/shared/store/global/global.mutations'
import localeHelper from '@/shared/helpers/locale.helper'
import { NavigationState } from '@/shared/store/global/global.state'
import { IDialog } from '@/shared/interfaces/helpers/dialog.interface'
import ISnackBar from '@/shared/interfaces/helpers/snack-bar.interface'
import Echo from 'laravel-echo'
import IGallery from '@/shared/interfaces/helpers/gallery.interface'

export enum GlobalActions {
  setLocale = 'GLOBAL/SET_LOCALE',
  toggleNavigation = 'GLOBAL/TOGGLE_NAVIGATION',
  setNavigation = 'GLOBAL/SET_NAVIGATION',
  showLoading = 'GLOBAL/SHOW_LOADING',
  showDialog = 'GLOBAL/SHOW_DIALOG',
  showSecondaryDialog = 'GLOBAL/SHOW_SECONDARY_DIALOG',
  closeDialog = 'GLOBAL/CLOSE_DIALOG',
  closeSecondaryDialog = 'GLOBAL/CLOSE_SECONDARY_DIALOG',
  showSnackBar = 'GLOBAL/SHOW_SNACK_BAR',
  setEchoServerInstance = 'GLOBAL/SET_ECHO_SERVER_INSTANCE',
  showGallery = 'GLOBAL/SHOW_GALLERY',
  closeGallery = 'GLOBAL/CLOSE_GALLERY',
  setHeadersHeight = 'GLOBAL/SET_HEADERS_HEIGHT',
}

export default {
  [GlobalActions.setLocale]: ({ commit, state }: ActionContext<IGlobalState, any>, payload: string) => {
    if (state.currentLocale !== payload) {
      history.go(0)
    }

    commit(GlobalMutations.setLocale, payload)
    localeHelper.setLocale(payload)
  },
  [GlobalActions.toggleNavigation]: ({ commit, state }: ActionContext<IGlobalState, any>): void => {
    let next = NavigationState.minimized

    if (state.navigation === NavigationState.minimized) {
      next = NavigationState.default
    }

    commit(GlobalMutations.setNavigation, next)
  },
  [GlobalActions.setNavigation]: ({ commit }: ActionContext<IGlobalState, any>, payload: NavigationState): void => commit(GlobalMutations.setNavigation, payload),
  [GlobalActions.showLoading]: ({ commit }: ActionContext<IGlobalState, any>, payload: boolean): void => commit(GlobalMutations.setLoading, payload),
  [GlobalActions.showDialog]: ({ commit }: ActionContext<IGlobalState, any>, payload: IDialog): void => commit(GlobalMutations.setDialog, payload),
  [GlobalActions.showSecondaryDialog]: ({ commit }: ActionContext<IGlobalState, any>, payload: IDialog): void => commit(GlobalMutations.setSecondaryDialog, payload),
  [GlobalActions.closeDialog]: ({ commit }: ActionContext<IGlobalState, any>): void => commit(GlobalMutations.resetDialog),
  [GlobalActions.closeSecondaryDialog]: ({ commit }: ActionContext<IGlobalState, any>): void => commit(GlobalMutations.resetSecondaryDialog),
  [GlobalActions.showSnackBar]: ({ commit }: ActionContext<IGlobalState, any>, payload: ISnackBar | null): void => commit(GlobalMutations.setSnackBar, payload),
  [GlobalActions.setEchoServerInstance]: ({ commit }: ActionContext<IGlobalState, any>, payload: Echo): void => commit(GlobalMutations.setEchoServerInstance, payload),
  [GlobalActions.showGallery]: ({ commit }: ActionContext<IGlobalState, any>, payload: IGallery): void => commit(GlobalMutations.setGallery, payload),
  [GlobalActions.closeGallery]: ({ commit }: ActionContext<IGlobalState, any>): void => commit(GlobalMutations.resetGallery),
  [GlobalActions.setHeadersHeight]: ({ commit }: ActionContext<IGlobalState, any>, payload: number): void => commit(GlobalMutations.setHeadersHeight, payload),
}
