












import { Component, Vue, Watch } from 'vue-property-decorator'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import { IDialog } from '@/shared/interfaces/helpers/dialog.interface'
import { GlobalActions } from '@/shared/store/global/global.actions'

@Component
export default class GlobalDialog extends Vue {
  @Watch('dialog') private handleDialogChanges(): void {
    if (this.dialog.show) {
      document.getElementsByTagName('html')[0].classList.add('dialog-is-open')
    } else {
      document.getElementsByTagName('html')[0].classList.remove('dialog-is-open')
    }
  }

  get dialog(): IDialog {
    return this.$store.getters[GlobalGetters.getDialog]
  }

  closeDialog(): void {
    this.$store.dispatch(GlobalActions.closeDialog)
  }
}
