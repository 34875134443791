import IRoutesMeta from '@/shared/interfaces/helpers/routes-meta.interface'
import { CompanyRoutes } from '@/shared/router/company'
import permissions from '@/shared/helpers/permissions.helper'

const companyRoutesMeta: IRoutesMeta = {
  [CompanyRoutes.profile]: {
    shouldLoggedIn: true,
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.projectsIndex]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.projects.index ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.projectsShow]: {
    initialRedirects: [
      CompanyRoutes.projectsShowTabIndex,
      CompanyRoutes.projectsShowTabDocuments,
      CompanyRoutes.projectsShowTabReports,
      CompanyRoutes.projectsShowTabTasksAndTime,
      CompanyRoutes.projectsShowTabViolations,
      CompanyRoutes.projectsShowTabChecklists,
    ],
    shouldLoggedIn: true,
    redirect: CompanyRoutes.projectsShow
  },
  [CompanyRoutes.projectsShowTabIndex]: {
    permissions: [ permissions.company.projects.show ],
    redirect: CompanyRoutes.projectsShow
  },
  [CompanyRoutes.projectsShowTabDocuments]: {
    permissions: [ permissions.company.groups.tree ],
    redirect: CompanyRoutes.projectsShow,
  },
  [CompanyRoutes.projectsShowTabReports]: {
    permissions: [ permissions.company.reports.projects ],
    redirect: CompanyRoutes.projectsShow,
  },
  [CompanyRoutes.projectsShowTabChecklists]: {
    permissions: [ permissions.company.checklists.index ],
    redirect: CompanyRoutes.projectsShow
  },
  [CompanyRoutes.projectsShowTabEmployees]: {
    permissions: [ permissions.company.projectEmployees.index ],
    redirect: CompanyRoutes.projectsShow
  },
  [CompanyRoutes.projectsCreate]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.projects.create ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.projectsEdit]: {
    shouldLoggedIn: true,
    permissions: [
      permissions.company.projects.show,
      permissions.company.projects.update
    ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.tasksIndex]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.tasks.index ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.violationsIndex]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.violations.index ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.employeesIndex]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.employees.index ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.employeesCreate]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.employees.create ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.employeesEdit]: {
    shouldLoggedIn: true,
    permissions: [
      permissions.company.employees.show,
      permissions.company.employees.update
    ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.clientsIndex]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.clients.index ],
    redirect: CompanyRoutes.projectsIndex,
  },
  [CompanyRoutes.clientsCreate]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.clients.create ],
    redirect: CompanyRoutes.projectsIndex,
  },
  [CompanyRoutes.clientsEdit]: {
    shouldLoggedIn: true,
    permissions: [
      permissions.company.clients.show,
      permissions.company.clients.update,
    ],
    redirect: CompanyRoutes.projectsIndex,
  },
  [CompanyRoutes.taskTypesIndex]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.taskTypes.index ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.taskTypesCreate]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.taskTypes.create ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.taskTypesEdit]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.taskTypes.show, permissions.company.taskTypes.update ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.checklistsIndex]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.checklists.index ],
    redirect: CompanyRoutes.projectsIndex,
  },
  [CompanyRoutes.checklistsShow]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.checklists.show ],
    redirect: CompanyRoutes.projectsIndex,
  },
  [CompanyRoutes.reportsIndex]: {
    shouldLoggedIn: true,
    initialRedirects: [
      CompanyRoutes.reportsIndexTabPaidHours,
      CompanyRoutes.reportsIndexTabAllHours,
      CompanyRoutes.reportsIndexTabCollectedHours,
      CompanyRoutes.reportsIndexTabProjects,
    ],
    redirect: CompanyRoutes.reportsIndex,
  },
  [CompanyRoutes.reportsIndexTabAllHours]: {
    permissions: [ permissions.company.reports.allHours ],
    redirect: CompanyRoutes.projectsIndex,
  },
  [CompanyRoutes.reportsIndexTabPaidHours]: {
    permissions: [ permissions.company.reports.paidHours ],
    redirect: CompanyRoutes.projectsIndex,
  },
  [CompanyRoutes.reportsIndexTabCollectedHours]: {
    permissions: [ permissions.company.reports.collectedHours ],
    redirect: CompanyRoutes.projectsIndex,
  },
  [CompanyRoutes.info]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.info ],
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.permissions]: {
    shouldLoggedIn: true,
    permissions: [ permissions.company.permissions.index, permissions.company.permissions.update ],
    redirect: CompanyRoutes.projectsIndex,
  },
  [CompanyRoutes.login]: {
    shouldNotLoggedIn: true,
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.remindPassword]: {
    shouldNotLoggedIn: true,
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.resetPassword]: {
    shouldNotLoggedIn: true,
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.setPassword]: {
    shouldNotLoggedIn: true,
    redirect: CompanyRoutes.projectsIndex
  },
  [CompanyRoutes.logout]: {}
}

export default companyRoutesMeta
