









import { Component, Vue } from 'vue-property-decorator'
import { MutationPayload } from 'vuex/types'
import { GlobalGetters } from '../store/global/global.getters'
import { GlobalMutations } from '../store/global/global.mutations'
import { GlobalActions } from '../store/global/global.actions'
import ISnackBar from '@/shared/interfaces/helpers/snack-bar.interface'
import __ from '@/shared/helpers/__'

@Component({
  methods: { __ }
})
export default class Snackbar extends Vue {
  snackbar: ISnackBar | null = this.$store.getters[GlobalGetters.getSnackBar]

  created() {
    this.$store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === GlobalMutations.setSnackBar) {
        this.snackbar = this.$store.getters[GlobalGetters.getSnackBar]

        if (this.snackbar) setTimeout(this.closeSnackbar, 5000)
      }
    })
  }

  closeSnackbar() {
    this.$store.dispatch(GlobalActions.showSnackBar, null)
  }
}
