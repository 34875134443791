import http from '@/shared/http';
import { getApiUrlByVersion, getLanguagesByVersion } from '@/config'
import localeHelper from '@/shared/helpers/locale.helper'
import { DEFAULT_LOCALE } from '@/shared/constants/locales.constants'

const key = 'version'

export const getVersion = (): string => {
  // If its LIVE
  if (window.location.hostname.indexOf('login.nsystem') !== -1) return window.location.hostname.split('.')[2]

  // If its DEV
  return localStorage.getItem(key) || 'no'
}
export const setVersion = (version: string) => {
  localStorage.setItem(key, version)

  if (! getLanguagesByVersion().some((language: string) => localeHelper.getLocale() === language)) {
    localeHelper.setLocale(DEFAULT_LOCALE)
  }

  http.defaults.baseURL = getApiUrlByVersion()
}
