import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import { ITranslatable } from '@/shared/interfaces/classes/model.interfaces'
import IStatusResponse from '@/shared/modules/status/status-response.interface'

export default class Status extends Model<IModelResponse> {
  name!: ITranslatable<string>|string
  meta!: any

  transform(data: IStatusResponse) {
    super.transform(data)

    this.name = data.name
    this.meta = data.meta
  }
}
