import Model from '@/shared/classes/model'
import IUserResponse from '@/shared/modules/user/user-response.interface'
import Role, { RoleLevel } from '@/shared/modules/role/role.model'
import Company from '@/shared/modules/company/company.model'
import Position from '@/shared/modules/position/position.model'
import Status from '@/shared/modules/status/status.model';
import PendingToolMove from '@/shared/modules/pending-tool-move/pending-tool-move.model'

export default class User extends Model<IUserResponse> {
  static ALIAS = 'user'
  static STATUS_ACTIVE = 'user.active'
  static STATUS_INACTIVE = 'user.inactive'

  avatar!: any
  firstName!: string
  lastName!: string
  fullName!: string
  email!: string
  birthDate!: string
  address!: string
  phone!: string
  defaultLocale!: string
  workFrom!: string
  workTo!: string
  permissions!: string[]
  roleUuid!: string
  companyUuid!: string | null
  positionUuid!: string | null
  statusUuid!: string | null
  clothingSize!: string | null
  shoeSize!: string | null
  companyCode!: string | null
  contactPerson!: string | null
  hseHandbookSignatures!: number
  lastSentInfo?: object
  lastSignedInfo?: object

  role!: Role
  company!: Company
  position!: Position
  status!: Status
  pendingToolMoves!: PendingToolMove[]

  transform(data: IUserResponse): void {
    super.transform(data)

    this.avatar = data.avatar
    this.firstName = data.first_name || data.firstName
    this.lastName = data.last_name || data.lastName
    this.fullName = data.full_name || data.fullName
    this.email = data.email
    this.birthDate = data.birth_date || data.birthDate
    this.address = data.address
    this.phone = data.phone
    this.defaultLocale = data.default_locale || data.defaultLocale
    this.workFrom = data.work_from || data.workFrom
    this.workTo = data.work_to || data.workTo
    this.permissions = data.permissions
    this.roleUuid = data.role_uuid
    this.companyUuid = data.company_uuid || data.companyUuid
    this.positionUuid = data.position_uuid
    this.statusUuid = data.status_uuid
    this.clothingSize = data.clothing_size || data.clothingSize
    this.shoeSize = data.shoe_size || data.shoeSize
    this.companyCode = data.company_code || data.companyCode
    this.contactPerson = data.contact_person || data.contactPerson
    this.hseHandbookSignatures = data.hseHandbookSignatures || 0
    this.lastSentInfo = data.lastSentInfo
    this.lastSignedInfo = data.lastSignedInfo

    this.role = this.setRelation(Role, data.role)
    this.company = this.setRelation(Company, data.company)
    this.position = this.setRelation(Position, data.position)
    this.status = this.setRelation(Status, data.status)
    this.pendingToolMoves = this.setRelation(PendingToolMove, data.pending_tool_moves)
  }

  get isSuperAdmin(): boolean {
    return this.role.level === RoleLevel.superadmin
  }
}
